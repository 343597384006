<template>
  <vs-card>
    <div slot="header">
      <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
        <vs-col vs-w="6">
          <h5 class="pt-2">Edit Product</h5>
        </vs-col>
        <vs-col vs-w="6">
          <ul style="display:block;text-align:right;">
            <li style="display:inline-block;margin-right:5px;">
              <vs-button type="flat" style="padding:5px 15px;" @click="shareProduct()">Share Product
              </vs-button>
            </li>
            <li style="display:inline-block;margin-right:5px;">
              <vs-button type="flat" style="padding:5px 15px;" @click="showLogs = !showLogs">View Logs
              </vs-button>
            </li>
            <li style="display:inline-block;margin-right:5px;">
              <vs-button type="flat" style="padding:5px 15px;" @click="duplicate()">Duplicate
              </vs-button>

            </li>
            <li style="display:inline-block;margin-right:5px;">
              <vs-button style="padding:5px 15px;" @click="onSave()">Save
              </vs-button>

            </li>
            <li style="display:inline-block">
              <vs-button style="padding:4px 15px;" @click="cancelHandler()" type="border">Cancel
              </vs-button>
            </li>
          </ul>
        </vs-col>

      </vs-row>
    </div>
    <div class="px-5">
      <ProductDetails :product="product" :save="isSave" :bundledItems="bundledItems" @createOrUpdateSupplier="createOrUpdateSupplier" @newBundleItems="addBundleItems" />
      <!-- <ProductDetails @createOrUpdateSupplier="createOrUpdateSupplier" :supplierId="supplierId" :onSave="save" /> -->
    </div>
    <vs-sidebar id="customSidebar" position-right  parent="body" default-index="0" color="primary" class="sidebarx overflow-auto" spacer v-model="showLogs">
      <div class="w-full bg-white pb-8" slot="header">
        <h4 class="text-3xl text-primary2">
          Activity
        </h4>
      </div>
      <div v-if="product.history.length">
        <div v-for="(value, index) in product.history" :key="value">
          <vs-sidebar-item :index="(index+1)" class="static-item">
            <span v-html="value"></span>
          </vs-sidebar-item>
          <vs-divider />
        </div>
      </div>
      <div v-else>
        <span class="p-5">No logs found.</span>
      </div>
    </vs-sidebar>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import axios from "@/axios";
import EditProductImage from "./EditProductImage";
import moment from "moment";
import ProductDetails from "./ProductDetails.vue"

export default {
  components: {
    Datepicker,
    EditProductImage,
    ProductDetails
  },
  data() {
    return {
      showLogs: false,
      showTickArray: [],
      loggedInUserType: "",
      suppliers: [],
      categories: [],
      roles: [],
      productTypes: ["Goods", "Training"],
      hasSelectedImages: false,
      hasMakeImageDefault: false,
      showVariantError: false,
      productId: "",
      outOfStock: false,
      currency: process.env.VUE_APP_CURRENCY,
      isSave: false,
      bundledItems: [],
      oldCustomDeliveryInfo: {
        supplierId: "",
        customDeliveryFee: ""
      },
      product: {
        name: "",
        productName: "",
        description: "",
        price: "",
        productPrice: "",
        compareAtPrice: "",
        inventory: {
          managedBy: "",
          sku: "",
          requiredQuantity: "",
          // barcode: ""
        },
        shipping: {
          shippingFee: "",
          weight: "",
          dimensions: {
            length: "",
            width: "",
            height: "",
          },
        },
        quantity: "",
        // supplierId: "",
        manufacturedDate: "",
        expiryDate: "",
        productType: "",
        visibleTo: "",
        s4Medication: false,
        // productConsent: "",
        // hasAcceptedConsent: false,
        images: [],
        documents: [],
        variants: [],
        categoryId: [],
        tc: null,
        tcLink: null,
        isTaxable: false,
        tags: [],
        directionForUse: "",
        type: "basic",
        productTypeDescription: "",
        productTypeHeading: "",
        options: [{value: ""}],
        minQty: null,
        maxQty: null,
        minErrorMessage: "",
        maxErrorMessage: "",
        priceHistory: {
          nursePrice: null,
          drPrice: null
        },
        brandId: null,
        subDescription: "",
        promotionalDescription: "",
        listPrice: "",
        isCompareAtPriceToggled: false,
        isListPriceToggled: false,
        isRRPHidden: true,
        isListPriceHidden: true,
        amount: null,
        treatmentProduct: null,
        pricePerMeasurement: {
          price: "",
          unit: "",
        },
        isPricePerMeasurementHidden: false,
        hidden: true,
        supplierSKU: "",
        isCustomDeliveryFee: false,
        customDeliveryFee: 0,
        evolusBundle: {
          qty: null,
          additionalQty: null,
          productStoreId: null,
          isActive: false,
        },
        KAPrice: 320
      },
    };
  },
  methods: {
    ...mapActions("rolesAndPermissions", ["getAllRoles"]),
    ...mapActions("storeProducts", [
      "fetchCategories",
      "fetchSuppliers",
      "addMoreProductImage",
      "updateProductImageStatus",
      "findProductById",
      "updateProduct",
      "duplicateProduct",
      "fetchBundledItems",
      "updateBundleItems"
    ]),
    ...mapActions("ecommerce",["algoliaSync"]),

    onSave() {
      this.isSave = true;
      setTimeout(() => {
        this.isSave = false
      }, 1000)
    },
    shareProduct(){
      const domain = window.location.origin;
      const url = domain + "/p/" + this.productId
      console.log(url)
      navigator.clipboard.writeText(url).then(() => {
        this.$vs.notify({
          title: 'Success',
          text: "Product URL copied successfully!",
          color: 'success',
        });
      }).catch(err => {
        console.log("Failed to copy text: ", err);
      });
    },
    duplicate() {
      this.$vs.loading();
      this.duplicateProduct({ id: this.productId }).then(success => {
        this.algoliaSync()
        this.$vs.notify({
          title: "Product Duplicated",
          text: "Product duplicated successfully",
          color: "success",
        });
        this.$vs.loading.close();
        this.$router.replace({ params: { id: success.data.data._id } });
      }).catch(err => {
        console.log(err);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: err.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      })
    },
    createOrUpdateSupplier(data) {
      this.$vs.loading();
      const self = this;
      const { supplierId, customDeliveryFee } = this.oldCustomDeliveryInfo
      const isUpdateCustomDeliveryInfo = supplierId !== data.inventory.managedBy && customDeliveryFee === data.customDeliveryFee

      this.updateProduct({
        id: this.productId,
        data: { ...data, isUpdateCustomDeliveryInfo }
      }).then(async (success) => {
          self.algoliaSync();

          // update old custom delivery info
          this.oldCustomDeliveryInfo = {
            supplierId: data.inventory.managedBy,
            customDeliveryFee: data.customDeliveryFee
          }

          await this.updateBundledItems();
          // let listRoute = "/super-admin/store/products";

          // if (this.loggedInUserType === "admin")
          //   listRoute = "/admin/store/products";

          self.$vs.notify({
            title: "Success",
            text: "Product updated successfully",
            color: "success",
          });

          // this.$router.go(-1)
          this.fetchRequiredModules();
          self.$vs.loading.close();
        })
        .catch((err) => {
          self.$vs.loading.close();
          self.$vs.notify({
            title: "Failed",
            text: err.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    cancelHandler() {
      this.$router.go(-1)
      // if (this.loggedInUserType === "superAdmin") {
      //   this.$router.push("/super-admin/store/products");
      // }
      // if (user === "admin") {
      //   this.$router.push("/admin/store/products");
      // }
    },
    fetchRequiredModules() {
      this.$vs.loading();
      this
        .findProductById(this.productId)
        .then((product) => {
          this.product = {
            ...this.product,
            ...product.data.data,
            hidden: !product.data.data.hideStatus,
          };
          this.outOfStock = !product.data.data.isAvailable;
          this.hasSelectedImages = true;
          this.hasMakeImageDefault = true;
          this.oldCustomDeliveryInfo = {
            supplierId: product.data.data.inventory.managedBy,
            customDeliveryFee: product.data.data.customDeliveryFee
          }
          // this.oldSupplierId = product.data.data.inventory.managedBy
          if (product.data.data.compareAtPrice <= 0) {
            this.product.compareAtPrice = null;
          }
          if (product.data.data.listPrice <= 0) {
            this.product.listPrice = null;
          }
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err);
          this.$vs.loading.close();
        });
    },
    addBundleItems(product){
      if(_.find(this.bundledItems,{productId:product._id})){
        this.$vs.notify({
          title: "Product Add",
          text: "Product has been already added below.",
          color: "danger",
        });
        return false;
      }
      this.bundledItems.push({
        productId: product._id,
        quantity: product.quantity || 1, // Set default quantity to 1
        name: product.name
      })
    },
    async getBundledItems() {
      try {
        const res = await this.fetchBundledItems({parentProductId: this.productId});
        if (res.status == 200) {
          this.bundledItems = res.data.data;
        }
      } catch (err) {
        console.error("ERR: ", err);
      }
    },
    async updateBundledItems() {
      try {
        const payload = this.bundledItems.map((item) => {
          return {
            parentProductId: this.productId,
            productId: item.productId,
            quantity: item.quantity,
          }
        })
        await this.updateBundleItems({
          parentProductId: this.productId,
          items: payload
        });
      } catch (err) {
        console.error(err);
      }
    }
  },
  created() {
    this.loggedInUserType = this.$store.state.AppActiveUser.userType;
    this.productId = this.$route.params.id;
    this.fetchRequiredModules();
    this.getBundledItems();
  },
  watch: {
    '$route.params.id': {
      handler: function (id) {
        this.productId = id
        this.fetchRequiredModules();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.custom-error {
  color: #ea5455;
  font-size: 0.65rem;
}

.vs-con-textarea {
  margin-bottom: 0 !important;
}

.section-wrapper {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 35px;
  margin-top: 20px;
}

.section-wrapper strong {
  font-size: 15px;
  text-transform: uppercase;
  position: absolute;
  top: -10px;
  left: 30px;
  background: #fff;
  padding: 0 10px;
}

.dateClass {
  margin-top: 16px;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
}

.showImageDiv {
  align-items: center;
  justify-content: center;
  display: flex;
}

.register-img-wrapper {
  padding: 0;
  margin: 0 !important;
  margin-bottom: 5px !important;
}

.addMoreVariant {
  font-size: 13px;
  color: darkblue;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
  margin-right: 20px;
}

.removeVariant {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: darkred;
  font-size: 15px;
  font-weight: 900;
}

.to-uppercase input {
  text-transform: capitalize;
}

.d-none {
  display: none !important;
}

.non-accordion {
  margin-bottom: 20px;
  display: inline-block;
}

.child-category {
  margin-bottom: 20px;
}

.accordion-icon {
  float: right;
  background: #ddd;
  padding: 4px;
  margin-left: 27px !important;
  cursor: pointer;
}
.static-item {
  pointer-events: none;
}
</style>
<style >
#customSidebar > .vs-sidebar {
  max-width: 400px;
  border: none !important;
  padding: 10px;
}
#customSidebar > .vs-sidebar > .vs-sidebar--header {
  background-color: white;
  width: auto;
  padding: 10px 20px;
} 
#customSidebar > .vs-sidebar > .vs-sidebar--items {
  overflow: auto;
} 
#customSidebar > .vs-sidebar > .vs-sidebar--items > .vs-sidebar--item > a:active, a:visited, a:hover, a {
  color: #626262;
} 
</style>