<template>
  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
    <vs-col vs-type="flex" vs-justify="left" vs-align="left">
      <vs-button @click="popupActivo4 = true" class="mr-3" icon-pack="feather"
        >View/Edit Product Images</vs-button
      >
    </vs-col>

    <vs-popup fullscreen title="Product Images" :active.sync="popupActivo4">
      <vs-row
        class="mb-2"
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <h5 class="mb-2">Note: You must have one default image chosen.</h5>
        </vs-col>

        <vs-col class="text-right" vs-justify="right" vs-align="right" vs-w="6">
          <div
            style="float: right;"
            id="add-more-product-image"
            class="add-product-images vs-con-loading__container"
          >
            <label
              style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px; text-align: center; max-width: 195px; background: #3DC9B3; color: #fff; font-weight: 600;"
            >
              + Add Product Images
              <input
                type="file"
                multiple
                accept="image/*"
                @change="addProductImage($event, 'add-more-product-image')"
                id=""
                hidden
              />
            </label>
          </div>
        </vs-col>
      </vs-row>

      <vs-col
        v-for="(item, index) in images"
        :key="index"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="20%"
      >
        <div :id="`image-${item._id}`" class="vs-con-loading__container">
          <div class="mb-6 mr-1">
            <div class="img-div mb-2 relative">
              <img :src="item.image" class="" />
              <div class="image-trash absolute">
                <feather-icon
                  @click="removeImage(item._id, item.image, item.useAsDefault)"
                  icon="Trash2Icon"
                ></feather-icon>
              </div>
            </div>
            <vs-radio
              class=""
              :vs-value="true"
              vs-name="image-radio"
              v-model="item.useAsDefault"
              @change="toggleDefaultImage(item.image, item._id)"
              >Use As Default</vs-radio
            >
          </div>
        </div>
      </vs-col>
    </vs-popup>
  </vs-row>
</template>

<script>
import axios from "@/axios";

export default {
  props: ["images", "productId"],
  data() {
    return {
      popupActivo4: false
    };
  },
  methods: {
    removeImage(id, url, isDefault) {
      if (isDefault) return false;
      this.$emit("removeProductImage", id, url, this.productId);
    },
    toggleDefaultImage(imgUrl, imgId) {
      this.$emit("changeDefaultImage", imgUrl, imgId);
    },
    addProductImage(event, key) {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `#add-more-product-image`,
        text: "uploading",
        scale: 0.45
      });
      let self = this;
      let data = new FormData();
      for (let i = 0; i < event.target.files.length; i++)
        data.append("profileImage", event.target.files[i]);

      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };
      axios
        .post("/api/v1/store/products/uploadImages?key=" + key, data, config)
        .then(res => {
          if (res.data.length > 0) {
            this.$emit("addImagesToProduct", res.data);
          }
          self.$vs.loading.close(`#add-more-product-image > .con-vs-loading`);
        })
        .catch(err => {
          self.$vs.loading.close(`#add-more-product-image > .con-vs-loading`);
          self.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    }
  }
};
</script>

<style scoped>
.img-div {
  width: 150px;
  height: 150px;
}

.img-div img {
  width: 100%;
  height: 100%;
}

.image-trash {
  top: 0;
  cursor: pointer;
  color: #fff;
  background: darkred;
  padding: 2px 5px;
}
</style>
